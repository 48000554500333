<template>
  <div class="about pa-2">
    <v-card
        class="mx-auto my-12"
        max-width="574"
    >

      <v-img
          height="250"
          src="@/assets/img/DSC_1772-1.jpg"
      ></v-img>

      <v-card-title>Manage Your Order</v-card-title>

      <v-card-text>
        <v-alert
            v-model="orderSubmitted"
            text
            type="success"
        >
          Order submitted.
        </v-alert>
        <v-alert
            v-model="orderSubmitted"
            text
            type="info"
        >
          Booklet details can no longer be edited.
        </v-alert>
        <div class="mb-4 subtitle-3">
          <p>In this area you will see the details of your order. If you wish to change your order, you will need to
            contact the Customer Happiness Team on 01872 713344.</p>
        </div>
        <div class="mb-4 subtitle-3">
          Date of Wedding: {{ weddingDateFormatted }}
        </div>
        <div
            v-if="absentFriendsEnabled"
            class="mb-4 subtitle-3"
        >
          "Absent Friends" option selected
        </div>
        <div
            v-if="customCoversEnabled"
            class="mb-4 subtitle-3"
        >
          "Custom Covers" option selected
        </div>
      </v-card-text>

    </v-card>
    <preview-dialog/>
  </div>

</template>

<script>
import {form} from "@/mixins/form";
import PreviewDialog from "@/components/Dialogs/DialogPreview";

export default {
  name: 'Order',
  mixins: [form],
  components: {
    PreviewDialog
  },
  computed: {
    absentFriendsEnabled() {
      return this.$store.state.absentFriends
    },
    customCoversEnabled() {
      return this.$store.state.customCovers
    },
    weddingDateFormatted() {
      return this.$store.getters.getWeddingDateFormatted
    },
    allPagesComplete() {
      let allPagesComplete = true
      for (let i = 0; i < this.$store.state.pages.length; i++) {
        const page = this.$store.state.pages[i]
        if (page.complete === false) {
          // Check custom covers & absent friends modes, add exceptions accordingly.
          if (this.$store.state.absentFriends === false && page.id === "absentFriends") {
            continue
          }
          if (this.$store.state.customCovers === false) {
            if (page.id === "frontCover" || page.id === "backCover") {
              continue
            }
          } else {
            if (page.id === "theme") {
              continue
            }
          }
          allPagesComplete = false
        }
      }
      return allPagesComplete
    },
    orderSubmitted() {
      if (this.$store.state.submittedOrder.regular.sourceOrderId) {
        return true
      } else {
        return false
      }
    }
  },
}
</script>
